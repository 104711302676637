export class MenuService {
    private menuItems = [
        {
          id: '1',
          name: 'Dashboard',
          icon: 'home',
          link: '/dashboard'
        },
        /* Hide non-functionals
        {
          id: '2',
          name: 'Cases',
          icon: 'cases',
          link: '/cases'
        },
        {
          id: '3',
          name: 'My Account',
          icon: 'account',
          link: '/account'
        }
        */
    ];

    getMenuItems() {
        return this.menuItems
    }
}