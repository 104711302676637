<div class="flex align-items-center justify-content-center overflow-hidden" style="height: 100%;">
    <div class="flex flex-column align-items-center justify-content-center">
        <div>
            <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style="border-radius:53px">
                <span class="text-blue-500 font-bold text-3xl">404</span>
                <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2">Not Found</h1>
                <div class="text-600 mb-5">Requested resource is not available.</div>
            </div>
        </div>
    </div>
</div>