import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { DashboardComponent } from './dashboard/dashboard.component';
import { CasesComponent } from './cases/cases.component';
import { ReportComponent } from './report/report.component';
import { AccountComponent } from './account/account.component';
import { SettingsComponent } from './settings/settings.component';
import { NotfoundComponent } from './notfound/notfound.component';

import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'cases',
        component: CasesComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'report',
        component: ReportComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'account',
        component: AccountComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [MsalGuard]
    },
    {
        path: '**',
        component: NotfoundComponent,
        canActivate: [MsalGuard]
    }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false })],
    exports: [RouterModule]
})
export class AppRoutingModule {}