import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../menu/menu.service';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  providers: [Router, AuthService]
})
export class HeaderComponent {
  @Input({ required: true }) fullMode!: boolean;
  readonly menuService = new MenuService();

  items: MenuItem[] | undefined;
  home: MenuItem | undefined;
  userItems: MenuItem[] | undefined;

  username = '';

  get menuItems() {
    return this.menuService.getMenuItems();
  }

  constructor(readonly router: Router, readonly authService: AuthService) {}

  ngOnInit() {
    let item = this.menuItems.find((el) => el.link === this.router.url)

    this.items = [{ icon: item?.icon, link: item?.link, name: item?.name }];

    this.home = { icon: 'pi pi-home' };

    this.userItems = [
        { label: 'Logout', icon: 'pi pi-sign-out', command: () => this.logout() }
    ];

    this.username = this.authService.getUserDetails().name;
  }

  get breadcrumb() {
    let item = this.menuItems.find((el) => el.link === this.router.url)
    let items = [{ icon: item?.icon, link: item?.link, name: item?.name }];

    return items
  }

  iconPath(icon:any) {
    return icon ? 'assets/icons/' + icon + '_header.png' : '';
  }

  getInitials() {
    let parts = this.username.includes(' ') ? this.username.split(' ') : this.username.split('.')

    if (parts.length > 1) {
      return (parts[0].slice(0,1) + parts[1].slice(0,1)).toUpperCase();    
    }
      
    return this.username.slice(0,1).toUpperCase();
  }

  logout() {
    this.authService.microsoftLogout();
  }
}
