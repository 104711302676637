import { Component, Input } from '@angular/core';

import { MenuService } from './menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent {
  @Input({ required: true }) fullMode!: boolean;
  readonly menuService = new MenuService();
  
  get menuItems() {
    return this.menuService.getMenuItems();
  }
}
