<div [class]="{'menu-main-div': true, 'minimize': !fullMode}">
    <div [class]="{'logo-div': true, 'minimize': !fullMode}">
        <img id="menu-logo" [src]="fullMode ? 'assets/logo.png' : 'assets/logo-icon.png'" alt="">
    </div>
    <div [class]="{'line-div': true, 'minimize': !fullMode}">
        <img class="menu-line top" [src]="fullMode ? 'assets/vector.png' : 'assets/vector-minimize.png'" alt="">
    </div>
    <div [class]="{'menu-items': true, 'minimize': !fullMode}">
        @for (item of menuItems; track item.id) {
            <app-menu-item
                [id]="item.id"
                [name]="item.name"
                [icon]="item.icon"
                [link]="item.link"
                [minimize]="!fullMode">
            </app-menu-item>
        }
    </div>
    <!-- Hide non-functionals
    <p-button 
        [label]="fullMode ? 'Create new case' : ''" 
        [icon]="fullMode ? '' : 'pi pi-plus'"
        size="small"
        [styleClass]="fullMode ? 'menu-custom-btn' : 'menu-custom-btn minimize'" />
    -->
    <!--
    <img class=" menu-line bottom" id="menu-line" [src]="fullMode ? 'assets/vector.png' : 'assets/vector-minimize.png'">

    <div [class]="{'avatar-div': true, 'minimize': !fullMode}">
        <p-avatar
            class="avatar"
            label="D" 
            styleClass="mr-2" 
            [style]="{ width: '41px', height: '41px', 'font-size': '24px' }" 
            shape="circle" />
        @if (fullMode) {
            <div class="avatar-name">Derek</div>
            <div class="avatar-title">Investigator</div>
        }
    </div>
    -->
</div>