import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss'],
})
export class CustomButtonComponent {
  @Input() label: string = 'Button'; // Button text
  @Input() type: 'button' | 'submit' | 'reset' = 'button'; // Button type
  @Input() disabled: boolean = false; // Disable the button
  @Input() styleClass: string = ''; // Additional CSS classes
  @Input() icon: string | null = null; // Optional icon

  @Output() clicked = new EventEmitter<void>(); // Event emitter for click

  onClick() {
    this.clicked.emit();
  }
}
