import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  
  constructor(
    private http: HttpClient,
    readonly authService: AuthService,
    @Inject('API_BASE_URL') private baseUrl: string
  ) {}

  search(tags: string[], from: any, size: any): Observable<any> {
    return new Observable((observer) => {
      this.authService.acquireToken().then((token) => {
        if (token) {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json'
          });
          
          // Construct the request body or parameters
          const body = {
            query: tags,
            from: from,
            size: size
          };

          this.http
            .post(`${this.baseUrl}/search`, body, { headers })
            .subscribe(
              (response) => observer.next(response),
              (error) => observer.error(error)
            );
        } else {
          observer.error('No token acquired.');
          this.authService.microsoftLogin();
        }
      });
    });
  }
}
