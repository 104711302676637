import { NgModule, importProvidersFrom } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CommonModule, NgFor, NgIf, DatePipe } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, RouterOutlet } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from './shared/shared.module';
import { HeaderComponent } from "./header/header.component";
import { MenuComponent } from "./menu/menu.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AuthInterceptor } from './services/auth.interceptor';

import { MenuItemComponent } from './menu/menu-item/menu-item.component';

import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputTextModule } from 'primeng/inputtext';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { MenuModule } from 'primeng/menu';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { environment } from "../environments/environment";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import {
    MsalModule,
    MsalRedirectComponent,
    MsalGuard,
    MsalInterceptor } from "@azure/msal-angular";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

const msalConfig = environment.mslConfig;

@NgModule ({
    declarations: [
        AppComponent,
        HeaderComponent,
        MenuComponent,
        MenuItemComponent,
        DashboardComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        CommonModule,
        NgIf,
        NgFor,
        DatePipe,
        FormsModule,
        ToolbarModule,
        ButtonModule,
        SplitButtonModule,
        InputTextModule,
        BreadcrumbModule,
        AvatarModule,
        BadgeModule,
        ProgressSpinnerModule,
        TableModule,
        TagModule,
        MenuModule,
        ConfirmPopupModule,
        MatIconModule,
        IconFieldModule,
        InputIconModule,
        RouterOutlet,
        RouterModule.forRoot([]),
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: msalConfig.clientId,
                authority: msalConfig.authority,
                redirectUri: msalConfig.redirectUri
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: isIE,
            }
        }), {
            interactionType: InteractionType.Redirect, // Msal Guard Configuration
            authRequest: {
                scopes: ["user.read"],
            },
        }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: new Map([])
        })
    ],
    exports: [],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: 'API_BASE_URL', useValue: environment.apiBaseUrl },
        MsalGuard,
        importProvidersFrom([BrowserAnimationsModule])
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
