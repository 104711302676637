export const environment = {
    production: false,
    apiBaseUrl: "https://search-dev.sight-connect.com",

    // msal config details
    mslConfig: {
        clientId: "36a13632-5614-47f1-8c3e-ebe505002b10",
        authority: "https://login.microsoftonline.com/e0730a64-4d3c-4fa5-a023-423153c984fe",
        redirectUri: 'https://portal-dev.sight-connect.com'
    }
};
