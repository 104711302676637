<header [class]="fullMode ? '' : 'minimize'">
    <p-toolbar>
        <div class="p-toolbar-group-start">
            <p-breadcrumb
                class="max-w-full custom-breadcrumb"
                [model]="breadcrumb">
                <ng-template pTemplate="item" let-item>
                    <a class="cursor-pointer" [routerLink]="item.url">
                        @if (item.icon) {
                            <img class="breadcrumb-icon" [src]="iconPath(item.icon)" alt="">
                            <span class="breadcrumb-label">{{ item.name }}</span>
                        }
                    </a>
                </ng-template>
                <ng-template pTemplate="separator"> / </ng-template>
            </p-breadcrumb>
        </div>
        <div class="p-toolbar-group-end" [style]="{ 'margin-right': '15px'}">
            <div class="flex align-items-center gap-2">
                <!-- Hide non-functionals
                <img class="breadcrumb-icon-badge" src="assets/icons/bell_badge.png" alt="">
                <img class="breadcrumb-icon-badge" src="assets/icons/message_badge_active.png" alt="">
                -->
                <span class="avatar-name">
                    {{ username}}
                </span>
                <p-menu #menu [model]="userItems" [popup]="true" />
                <p-avatar
                    class="avatar"
                    [label]="getInitials()"
                    styleClass="mr-2" 
                    [style]="{ width: '36px', height: '36px', 'font-size': '16px' }" 
                    shape="circle"
                    (click)="menu.toggle($event)" />
            </div>
        </div>
    </p-toolbar>
</header>
