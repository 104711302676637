import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { SilentRequest, AuthenticationResult, RedirectRequest, InteractionRequiredAuthError } from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  
  [x: string]: any;
  profile: any;
  token: string | null = null;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, readonly msalService: MsalService) { }

  getUserDetails(){
    const profileData = this.msalService.instance.getAllAccounts()[0];
    this.profile = {name: profileData.name, mail: profileData.username}
    return this.profile;
  }

  microsoftLogin() {
    if (this.msalGuardConfig.authRequest){
      this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  microsoftLogout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: location.origin
    });

    localStorage.removeItem('access_token');
    this.isAuthenticatedSubject.next(false);
  }

  // Handle the redirect result using handleRedirectObservable
  handleRedirectCallback() {
    this.msalService.handleRedirectObservable().subscribe(
      (response: AuthenticationResult) => {
        if (response) {
          // console.log(response)
          // this.saveToken(response.accessToken);
          // this.saveToken(response.idToken);
          this.saveToken(response.idToken);
          this.isAuthenticatedSubject.next(true);
          // console.log('Login successful');
        }
      },
      (error) => {
        console.error('Login failed', error);
      }
    );
  }

  // Acquire token silently
  acquireToken(): Promise<string | null> {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount) {
      console.error('No active account found');
      return Promise.resolve(null);
    }

    const silentRequest: SilentRequest = {
      account: activeAccount,
      scopes: ['user.read'], // Replace with your required scopes
    };

    return this.msalService.instance
      .acquireTokenSilent(silentRequest)
      .then((response) => {
        this.saveToken(response.idToken);
        return response.idToken;
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          console.error('Interaction required to acquire token.');
          // Redirect or popup login here
          this.msalService.loginRedirect(silentRequest);
        } else {
          console.error('Acquire token silent error:', error);
        }
        return null;
      });
  }

  // Save the access token to localStorage
  private saveToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  // Retrieve the access token from localStorage
  getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  // Check if the user is authenticated
  isAuthenticated(): boolean {
    return this.isAuthenticatedSubject.value;
  }
}