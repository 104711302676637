import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.scss'
})
export class MenuItemComponent {
  @Input({ required: true }) id!: string;
  @Input({ required: true }) name!: string;
  @Input({ required: true }) icon!: string;
  @Input({ required: true }) link!: string;
  @Input({ required: true }) minimize!: boolean;

  constructor(readonly router: Router) {}

  get iconPath() {
    let isActive = this.router.url === this.link
    return 'assets/icons/' + this.icon + (isActive ? '_active.png' : '.png');
  }
}
