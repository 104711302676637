<ng-container *ngIf="!isAuthenticated()">
    <app-full-screen-loader></app-full-screen-loader>
</ng-container>

<ng-container *ngIf="isAuthenticated()">
    <app-menu [fullMode]="fullMode()" />
    <app-header [fullMode]="fullMode()"></app-header>
    <div [class]="{'canvas': true, 'minimize': !fullMode()}">
        <router-outlet />
    </div>
</ng-container>
