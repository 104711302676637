import { Component } from '@angular/core';

@Component({
  selector: 'app-full-screen-loader',
  templateUrl: './full-screen-loader.component.html',
  styleUrl: './full-screen-loader.component.scss'
})
export class FullScreenLoaderComponent {

}
