<button
  [type]="type"
  [class]="styleClass"
  [disabled]="disabled"
  (click)="onClick()"
>
  <!-- Icon (if provided) -->
  <span *ngIf="icon" class="button-icon">
    <i [class]="icon"></i>
  </span>

  <!-- Label -->
  <span class="button-label">{{ label }}</span>
</button>
