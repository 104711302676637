<div class="grid">
	<!--<div class="col-12 xl:col-6">-->
	<div class="col-12">
		<div class="grid">
			<div class="col-12 flex align-items-center justify-content-between title-div">
				<div class="page-title">Dashboard</div>
				<div>
					<!-- Hide non-functionals
					<app-custom-button
						label="Upload"
						[type]="'button'"
						[disabled]="false"
						[styleClass]="'primary-button'"
						[icon]="'pi pi-upload'"
						(clicked)="onButtonClick()">
					</app-custom-button>
					<app-custom-button
						label="Date"
						[type]="'button'"
						[disabled]="false"
						[styleClass]="'primary-button'"
						[icon]="'pi pi-calendar'"
						(clicked)="onButtonClick()">
					</app-custom-button>
					<app-custom-button
						label="Filter"
						[type]="'button'"
						[disabled]="false"
						[styleClass]="'primary-button'"
						[icon]="'pi pi-filter'"
						(clicked)="onButtonClick()">
					</app-custom-button>
					-->
				</div>
			</div>
			<div class="col-12 flex search-input-div">
					<p-iconField iconPosition="left" class="w-full">
						<p-inputIcon styleClass="pi pi-search" />
						<input
							type="text"
							pInputText
							placeholder="Enter keywords to search ..."
							[(ngModel)]="query"
							(keydown.enter)="addTag()"
							class="w-full"/>
					</p-iconField>
			</div>
			<div class="col-12 tags">
				<span *ngFor="let tag of tags; let i = index">
					<p-tag>
						<div class="flex align-items-center gap-2 px-1">
							<span>{{ tag }}</span>
							<p-inputIcon styleClass="pi pi-times" (click)="removeTag(i)" />
						</div>
					</p-tag>
				</span>
			</div>
			<div class="col-12 search-col" *ngIf="tags.length">
				<app-card class="full-height">
					<div class="flex justify-content-between align-items-center">
						<div class="content-title">Search results</div>
						<!-- Hide non-functionals
						<div>
							<span class="search-filter-menu">
								<span class="filter-label">Filter</span>
								<span class="filter-icon pi pi-angle-down"></span>
							</span>
						</div>
						-->
					</div>
					<!-- Hide non-functionals
					<div class="flex">
						<div class="search-option active">Recent</div>
						<div class="search-option">Trending</div>
					</div>
					-->
					<!-- Search Results -->
					<div class="results">
						<p-table
							#searchTable
							[value]="results"
							[scrollable]="true"
							selectionMode="single"
							scrollHeight="calc(100vh - 360px)"
							[lazy]="true"
							[rows]="size"
							[totalRecords]="totalResults"
    						[virtualScroll]="true"
							virtualScrollItemSize="80"
							dataKey="id"
							[(selection)]="selectedRow"
							[loading]="loading"
							(onLazyLoad)="onLazyLoad($event)"
							(onRowSelect)="onRowSelect($event)" 
    						(onRowUnselect)="onRowUnselect($event)">
							<ng-template pTemplate="header">
								<tr>
									<th>Content</th>
									<th>Type</th>
									<th>Source</th>
									<th>Time</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-item>
								<tr [pSelectableRow]="item" style="height:80px">
									<td class="td-content">{{ item.body }}</td>
									<td>post</td>
									<td>
										<p-tag value="Forum" severity="contrast" />
									</td>
									<td class="td-time">{{ item.time | date: 'dd.MM.yyyy hh:mm'}}</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</app-card>
			</div>
		</div>
	</div>
</div>

