import { NgModule } from "@angular/core";

import { CardComponent } from './card/card.component'
import { FullScreenLoaderComponent } from "./full-screen-loader/full-screen-loader.component";
import { CustomButtonComponent } from './custom-button/custom-button.component';
import { NgFor, NgIf } from "@angular/common";

@NgModule({
    declarations: [CardComponent, FullScreenLoaderComponent, CustomButtonComponent],
    imports: [NgFor, NgIf],
    exports: [CardComponent, FullScreenLoaderComponent, CustomButtonComponent]
})
export class SharedModule {}