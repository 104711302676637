import { Component, ElementRef, ViewChild } from '@angular/core';
import { SearchService } from '../services/search.service';
import { debounceTime, distinctUntilChanged, finalize, Subject, switchMap } from 'rxjs';
import { Table, TableLazyLoadEvent } from 'primeng/table';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  providers: [SearchService]
})
export class DashboardComponent {
  @ViewChild('searchTable') table: Table | undefined;
  @ViewChild('searchTable', { read: ElementRef }) dataTableRef!: ElementRef; // Reference to table DOM element

  query: string = '';
  from: number = 0;
  size: number = 20;
  results: any[] = [];
  totalResults: number = 100;
  tags: string[] = [];
  loading: boolean = false;
  selectedRow = {};
  calls: number = 0;
  scrollPosition = { top: 0, left: 0 };
  lastEvent: any;

  private lazyLoadSubject: Subject<any> = new Subject();

  constructor(private searchService: SearchService) {}

  ngOnInit() {
    // Subscribe to the lazy load subject with switchMap to handle API calls
    this.lazyLoadSubject
      .pipe(
        debounceTime(300), // Wait 300ms after the last event
        distinctUntilChanged(), // Ignore if the next search term is the same as the previous
        switchMap((event) => {
          this.loading = true;
          return this.searchService.search(
            this.tags,
            event.first,
            event.rows
          ).pipe(
            finalize(() => (this.loading = false)) // Ensure loading indicator stops
          );
        })
      )
      .subscribe({
        next: (response:any) => {
          if (response.status) {
            let newData = response.data.hits.map((item:any) => ({
              id: item.id,
              time: item.last_crawled_at,
              title: item.title,
              body: item.body
            }));
            this.totalResults = response.data.total_hits;
            this.results = [...this.results, ...newData]; // Append new data

            setTimeout(() => {
              this.resetScrollPosition();
            }, 100);
          }
          // this.loading = false;
        },
        error: (err) => {
          this.loading = false;
        }
      });
  }

  addTag(): void {
    const trimmedQuery = this.query.trim();
    if (trimmedQuery && !this.tags.includes(trimmedQuery)) {
      this.tags.push(trimmedQuery);
    }
    this.onSearch();
  }

  removeTag(index: number): void {
    this.tags.splice(index, 1);
    if (this.tags.length) {
      this.onSearch();
    }
  }

  resetTableData(): void {
    if (this.table) {
      this.table.reset();
    }
    this.results = [];
    this.from = 0;
    this.size = 20;
    this.totalResults = 100;
    this.query = '';
    this.calls = 0;
    this.scrollPosition = { top: 0, left: 0 };
  }

  onSearch(): void {
    this.resetTableData();
    // this.loadData({ first: 0, rows: 20 });
    // this.lazyLoadSubject.next({ first: 0, rows: 20 });
  }

  onLazyLoad(event: any) {
    // Compare the new event with the last event
    if (JSON.stringify(event) === JSON.stringify(this.lastEvent)) {
      return;
    }
  
    this.lastEvent = event;
    this.loadData(event);
  }

  loadData(event: TableLazyLoadEvent): void {
    if (this.results.length > 0 && event.first === 0) {
      return; // Prevent multiple calls
    }
    
    // Calculate offset and limit based on the lazy loading event
    const offset = event.first ?? 0;
    event.first = event.first ?? 0;
    event.rows = this.size;

    if ((this.from < offset && offset < this.totalResults) || (this.results.length === 0 && this.calls < 2)) {
      this.loading = true;
      this.saveScrollPosition();
      this.calls++;
      this.from = offset

      this.lazyLoadSubject.next(event);
    }
  }

  saveScrollPosition(): void {
    if (this.dataTableRef) {
      const scrollableBody = this.dataTableRef.nativeElement.querySelector('.p-scroller');
      if (scrollableBody) {
        this.scrollPosition.top = scrollableBody.scrollTop;
        this.scrollPosition.left = scrollableBody.scrollLeft;
      }
    }
  }

  resetScrollPosition(): void {
    if (this.dataTableRef) {
      const scrollableBody = this.dataTableRef.nativeElement.querySelector('.p-scroller');
      if (scrollableBody) {
        scrollableBody.scrollTop = this.scrollPosition.top;
        scrollableBody.scrollLeft = this.scrollPosition.left;
      }
    }
    this.loading = false;
  }

  onRowSelect(event: any) {
    // this.messageService.add({ severity: 'info', summary: 'Product Selected', detail: event.data.name });
  }

  onRowUnselect(event: any) {
      // this.messageService.add({ severity: 'info', summary: 'Product Unselected', detail: event.data.name });
  }

  onButtonClick() {
    // console.log('click')
  }
}
