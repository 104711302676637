import { Component, HostListener, signal } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { AuthService } from './services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'
]
})
export class AppComponent {
  isAuthenticatedVal: boolean = false;
  title = '';
  fullMode = signal(true);
  innerWidth: number;

  loginStatus: boolean = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private readonly broadcastService: MsalBroadcastService, private readonly msalService: MsalService, private readonly authService: AuthService, private readonly route: Router) {
    this.innerWidth = window.innerWidth;

    this.broadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    )
    .subscribe((result: EventMessage) => {
      if (this.msalService.instance.getAllAccounts().length === 0) {
        // Redirect to Login page
        // this.route.navigate(['/login']);
        this.login();
      } else {
        this.setLoginDisplay();
      }
    });

    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
        this.setLoginDisplay();
    })
  }

  ngOnInit() {
    // Handle the redirect callback
    this.authService.handleRedirectCallback();

    // Subscribe to the authentication status
    this.authService.isAuthenticated$.subscribe((status) => {
      this.isAuthenticatedVal = status;
    });
  }

  setLoginDisplay() {
    this.loginStatus = this.msalService.instance.getAllAccounts().length > 0;

    if(this.loginStatus){
      const profile_data = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(profile_data);
    } else {
      console.log("Not logged-in");
      // Redirect to Login page
      // this.route.navigate(['/login']);
    }
  }

  isAuthenticated() {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  login() {
    this.authService.microsoftLogin(); // Call the microsoftLogin() method from the AuthService
  }

  logout() { // Add log out function here
    this.authService.microsoftLogout();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.innerWidth = (event.target as Window).innerWidth;
    if (this.innerWidth <= 800) {
      this.fullMode.set(false);
    } else {
      this.fullMode.set(true);
    }
  }
}
